import React from "react";
import Slideshow from "./Slideshow.js";

function Homepage() {
  return (
    <div className="Homepage">
      <Slideshow />
   
    </div>
  );
}

export default Homepage;
