import React from "react";


function Blog() {
  return (
    <div className="contain flex w-[90%] md:w-[80%] sm:w-[80%] mx-auto justify-between items-center flex-col md:flex-row my-[100px]">
     
     
    </div>
  );
}

export default Blog;
